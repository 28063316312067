import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: Rosewood Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectRosewoodPage() {
  return (
    <article
      id="page-portfolio-rosewood"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              Private Residence
            </sup>
            <div>Rosewood, La Cava</div>
          </>
        }
        content={<>Abu Dhabi, United Arab Emirates</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-rosewood/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Client</dt>
                <dd className="content">Rosewood Hotels & Resorts</dd>
              </div>
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">BBGM (Washington, D.C.)</dd>
              </div>
              <div className="detail">
                <dt className="title">Collaborator</dt>
                <dd className="content">Porsche Design Group (Germany)</dd>
              </div>
            </dl>
            <p>
              Technology intersects elegance with automated wine dispensing
              systems and individually conditioned displays.
            </p>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-rosewood/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-rosewood/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-rosewood/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">Automated wine dispensing system.</li>
              <li className="feature">5,000 bottle glass wine tower.</li>
              <li className="feature">Walk-in cigar room and humidor.</li>
              <li className="feature">
                Individual conditioned champagne displays with Porsche design.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-rosewood/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-rosewood/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "inspire-wall-mounted",
            title: "Inspire Collection",
            url: "products/inspire/wall-mounted/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-rosewood" />
    </article>
  )
}

export default ProjectRosewoodPage
